import { Box } from 'components/Box/Box';
import { AnimatedCheckmark } from 'components/Icon/AnimatedCheckmark';
import { CmsApiSubscribeLink } from 'modules/cms/api/types/CmsApiLink';
import { useCmsSubscribe } from 'modules/cms/hooks/useCmsSubscribe';
import { trackClicked } from 'utils/analytics/track/trackClicked';

import { CmsButtonRender } from './types/CmsButtonRender';

type Props = {
  buttonData: CmsApiSubscribeLink;
  eventProperties: Record<string, unknown>;
  blockId: string;
  sectionIndex: number;
  render: CmsButtonRender;
};

export function CmsSubscribeButton({
  sectionIndex,
  blockId,
  buttonData,
  eventProperties,
  render,
}: Props) {
  const { subscribe, isSubscribed } = useCmsSubscribe({
    blockId,
    index: sectionIndex,
    redirectUrl: buttonData.data.redirectUrl,
  });

  const onClick = () => {
    subscribe();
    trackClicked('CMS Block', {
      redirect_url: buttonData.data.redirectUrl,
      ...eventProperties,
    });
  };

  const children = isSubscribed ? (
    <Box display="flex" mr="8px">
      <AnimatedCheckmark size={24} colorName="white" />
      {getText('Subscribed')}
    </Box>
  ) : (
    buttonData.text
  );

  return render({
    data: { type: 'button', onClick, disabled: isSubscribed },
    'data-qa-id': 'cms-subscribe-button-link',
    children,
  });
}
