import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTemporaryStorage } from 'hooks/useTemporaryStorage';
import { CmsApiSubscribe } from 'modules/cms/api/types/CmsApiSubscribe';
import { RichError } from 'utils/RichError';
import { postJson } from 'utils/http/postJson';
import { trackError } from 'utils/trackError';
import { getParam } from 'utils/url/getParam';
import { isUrlWithProtocol } from 'utils/url/isUrlWithProtocol';
import { showModal } from 'zustand-stores/modalStore';
import { useUserEnvironment } from 'zustand-stores/userEnvironmentStore';

type Props = {
  blockId: string;
  index: number;
  redirectUrl?: string;
};

export function useCmsSubscribe({ blockId, index, redirectUrl }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUserEnvironment();
  const tmpIdFromParams = getParam(location, 'tmpId');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [, temporaryStorage] = useTemporaryStorage(
    Array.isArray(tmpIdFromParams) ? tmpIdFromParams[0] : tmpIdFromParams,
  );

  const subscribe = useCallback(() => {
    const showEmailSignupModal = (cmsSubscribe: CmsApiSubscribe) => {
      showModal('EMAIL_SIGNUP', {
        title: getText('Create an account on Idealist in order to subscribe'),
        cmsSubscribe,
        onLogin: () => {
          const postBaseUrl = '/data/website/button-link-campaign-signup/';
          postJson(postBaseUrl + blockId, {
            index,
          });

          if (typeof redirectUrl === 'string') {
            if (isUrlWithProtocol(redirectUrl)) {
              window.location.href = redirectUrl;
              return;
            }

            navigate(
              redirectUrl.startsWith('/') ? redirectUrl : `/${redirectUrl}`,
            );
          } else {
            setIsSubscribed(true);
          }
        },
      });
    };

    const loggedInVerifiedWithInfo = Boolean(
      user &&
        user.email !== null &&
        user.emailVerified &&
        user.firstName &&
        user.lastName,
    );

    if (loggedInVerifiedWithInfo) {
      const postBaseUrl = '/data/website/button-link-campaign-signup/';
      postJson(postBaseUrl + blockId, {
        index,
      })
        .then(() => {
          if ('cmsSubscribe' in temporaryStorage && user?.emailVerified) {
            if (typeof temporaryStorage.cmsSubscribe.redirectUrl !== 'string') {
              setIsSubscribed(true);
              return;
            }

            if (isUrlWithProtocol(temporaryStorage.cmsSubscribe.redirectUrl)) {
              window.location.href = temporaryStorage.cmsSubscribe.redirectUrl;
              return;
            }

            navigate(
              temporaryStorage.cmsSubscribe.redirectUrl.startsWith('/')
                ? temporaryStorage.cmsSubscribe.redirectUrl
                : `/${temporaryStorage.cmsSubscribe.redirectUrl}`,
            );
            return;
          }

          if (typeof redirectUrl === 'string') {
            if (isUrlWithProtocol(redirectUrl)) {
              window.location.href = redirectUrl;
              return;
            }

            navigate(
              redirectUrl.startsWith('/') ? redirectUrl : `/${redirectUrl}`,
            );
          } else {
            setIsSubscribed(true);
          }
        })
        .catch((e) => {
          trackError(
            // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            new RichError('Error subscribing in CMS block', JSON.stringify(e)),
          );
        });
    } else {
      showEmailSignupModal({ blockId, index, redirectUrl });
    }
  }, [user, blockId, index, temporaryStorage, redirectUrl, navigate]);

  useEffect(() => {
    if (
      'cmsSubscribe' in temporaryStorage &&
      temporaryStorage.cmsSubscribe.blockId === blockId &&
      temporaryStorage.cmsSubscribe.index === index &&
      user?.emailVerified
    ) {
      subscribe();
    }
  }, [user?.emailVerified, blockId, index, subscribe, temporaryStorage]);

  return { subscribe, isSubscribed };
}
